import React from "react";
import styled from "styled-components";

export default function HomePage() {
  const HomePageContainer = styled.div`
    padding: 2rem;
    overflow: auto;

    scrollbar-width: thin;
    scrollbar-color: var(--black) var(--light-grey);

    &::-webkit-scrollbar-thumb {
      background-color: var(--dark-grey);
      border-radius: 6px;
      border: 3px solid var(--light-grey);
    }

    &::-webkit-scrollbar-track {
      background: var(--light-grey);
    }

    &::-webkit-scrollbar {
      width: 12px;
    }
  `;

  return (
    <HomePageContainer className="justify long-text">
      The online Lexicon of Oriental Words in Ancient Greek is created by Rafał
      Rosół (Institute of Classical Philology, Adam Mickiewicz University in
      Poznań) in the framework of the project financed by the National Science
      Centre (Narodowe Centrum Nauki) in Poland (2020/39/B/HS2/00934). The
      lexicon that will be fully completed in 2025 contains Oriental loanwords
      and glosses attested in Greek sources from the Mycenaean period until the
      6th century AD. The words under investigation originate in Semitic,
      Egyptian, Indo-Aryan, Iranian, Anatolian, Phrygian, and other languages
      (including vocabulary without a determined source, especially
      Wanderwörter). After completing the project, the material will also be
      prepared for publication in a traditional book version with a
      comprehensive introduction, detailed conclusions and indices.
      <br />
      <br />
      Contact: rafros@amu.edu.pl
      <br />
      <br />© Rafał Rosół 2022
    </HomePageContainer>
  );
}
